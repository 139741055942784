import { useCallback } from "react";
import { useApi } from "../../../hooks/useApi";
import { Invoice, InvoiceFilter } from "../types/InvoiceTypes";

export const useInvoices = (): {
  searchInvoices: (
    limit: number,
    page: number,
    search: InvoiceFilter,
    order: Record<string, string>,
  ) => Promise<{ results: Invoice[]; total: string }>;
  getInvoice: (
    invoiceId: string,
  ) => Promise<{ content: string; filename: string }>;
} => {
  const { call } = useApi();

  const searchInvoices = useCallback(
    (
      limit: number,
      page: number,
      search: InvoiceFilter,
      order: Record<string, string>,
    ): Promise<{ results: Invoice[]; total: string }> =>
      call("/supercotrolia/invoices/search", {
        data: {
          limit,
          page,
          search,
          order,
        },
      }),
    [call],
  );

  const getInvoice = useCallback(
    (invoiceId: string): Promise<{ content: string; filename: string }> =>
      call(`/supercotrolia/invoices/${invoiceId}`),
    [call],
  );

  return {
    searchInvoices,
    getInvoice,
  };
};
